import { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { login, reset, autoLogin } from "../features/auth/authSlice"
import { FaEye, FaEyeSlash } from "react-icons/fa"
import Spinner from "../components/Spinner"
import ErrorInfo from "../components/ErrorInfo"
import { FaGoogle } from "react-icons/fa"
// import { ReactComponent as GoogleIcon } from "../assets/svg/google.svg"
import Btn from "../components/Button"

const Login = () => {
  const [formData, setFormData] = useState({ email: "", password: "" })
  const [showPassword, setShowPassword] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const { email, password } = formData

  const { isLoading, isError, user, message } = useSelector(
    (state) => state.auth
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      navigate("/app")

      return
    }

    const token = localStorage.getItem("token")
    const persistedLogin = JSON.parse(localStorage.getItem("persistedLogin"))
    const staySignedIn = persistedLogin === true ? true : false

    if (token && !user && !isLoading) {
      dispatch(autoLogin({ oldToken: JSON.parse(token), staySignedIn }))
      return
    }
  }, [dispatch, user, navigate, isLoading])

  useEffect(() => {
    // cleans up when component unmounts
    return () => dispatch(reset())
  }, [dispatch])

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    })
  }

  const handleCheckboxChange = (event) => {
    localStorage.setItem("persistedLogin", JSON.stringify(event.target.checked))
    setIsChecked(event.target.checked)
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    // dispatch(reset())
    dispatch(login({ email, password, staySignedIn: isChecked }))
  }

  return (
    <div className="h-screen min-h-fit  container mx-auto px-4 py-20">
      {isLoading && <Spinner />}
      <div className="md:flex lg:pl-40 mx-auto justify-center items-center gap-14">
        <div className="pt-20 z-10 max-w-xs mx-auto md:mx-0 text-faint-black">
          <header>
            <h1 className="text-2xl text-center font-semibold my-8">
              Sign <span className="text-primary">in</span>
            </h1>
          </header>
          <form
            className="form-control  w-full md:w-80 flex  flex-col gap-3 text-sm"
            onSubmit={onSubmit}
          >
            <div className="">
              <label className="label " htmlFor="email">
                Email
              </label>
              <input
                type="text"
                id="email"
                placeholder="Enter your email"
                className="input input-bordered border-faint-black w-full bg-transparent border  "
                value={email}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className="">
              <label className="label " htmlFor="password">
                Password
              </label>
              <div className="relative">
                <div className="absolute top-1/2 right-4 transform -translate-x-1/2 -translate-y-1/2  ">
                  <label className="swap">
                    <input type="checkbox" />
                    <div
                      className="swap-on"
                      onClick={() => setShowPassword(true)}
                    >
                      <FaEye />
                    </div>
                    <div
                      className="swap-off"
                      onClick={() => setShowPassword(false)}
                    >
                      <FaEyeSlash />
                    </div>
                  </label>
                </div>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="Enter your password"
                  className="input input-bordered border-faint-black w-full bg-transparent border  "
                  value={password}
                  onChange={(e) => onChange(e)}
                />
              </div>

              <div className="flex justify-center items-center">
                <label className="label inline-flex align-middle cursor-pointer">
                  <input
                    type="checkbox"
                    className="checkbox h-4 w-4 checkbox-primary"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                </label>
                <div className="inline-flex grow justify-between">
                  <span className="text-xs">Stay signed in</span>
                  <Link to="/forgot-password" className="text-xs  btn-link">
                    Forgot password?
                  </Link>
                </div>
              </div>

              {isError && <ErrorInfo message={message} />}
            </div>

            <div className="mx-auto flex flex-col gap-3 items-center justify-center w-full">
              <Btn
                text="Login"
                style={{
                  background:
                    "linear-gradient(180deg, #000DD8 0.54%, #3263F6 99.46%",
                }}
                type="generic"
                onClick={(e) => onSubmit(e)}
                classNames="w-full mx-auto md:mx-0 relative hover:top-px  max-w-xs btn-primary  text-white"
              />
              {/* <div className="text-xs text-center">or continue with</div>
              <Btn
                text="Continue with Google"
                type="generic"
                onClick={() => {
                  navigate("/register")
                }}
                classNames="w-full font-medium gap-3 btn-primary hover:bg-transparent text-sm mx-auto md:mx-0 relative hover:top-px  bg-transparent  max-w-xs  border-faint-black text-faint-black"
              >
                <FaGoogle />
                Continue with Google
              </Btn> */}
            </div>
          </form>
          <div className="w-full text-sm">
            Don't have an account?{" "}
            <Link to="/register" className="btn btn-link px-0 normal-case">
              Register
            </Link>
          </div>
        </div>

        <div
          className="h-64 hidden md:block  lg:h-80 max-w-md scale-150 bg-contain bg-no-repeat bg-center grow"
          style={{ backgroundImage: `url('/image2.png')` }}
        ></div>
      </div>
    </div>
  )
}
export default Login

import { useState } from "react"
import PropTypes from "prop-types"

const SkrillInputCard = ({ getInputData }) => {
  const [formData, setFormData] = useState({
    accountName: "",
    email: "",
  })

  const { accountName, email } = formData

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    })

    getInputData({
      ...formData,
      [e.target.id]: e.target.value,
    })
  }

  return (
    <div>
      <div className="bg-gray-50">
        <input
          value={email}
          onChange={onChange}
          type="text"
          id="email"
          placeholder="Enter your skrill email"
          className="input text-sm input-bordered w-full max-w-xs"
        />
      </div>

      <div className="bg-gray-50">
        <input
          value={accountName}
          onChange={onChange}
          id="accountName"
          type="text"
          placeholder="Enter your account name"
          className="input text-sm input-bordered w-full max-w-xs"
        />
      </div>
    </div>
  )
}

SkrillInputCard.propTypes = {
  getInputData: PropTypes.func.isRequired,
}

export default SkrillInputCard

import { useNavigate } from "react-router-dom"
import ExchangeCalculator from "../components/ExchangeCalculator"
import Btn from "../components/Button"

import accountability from "../assets/images/accountability.png"
import fast from "../assets/images/fast.png"
import secure from "../assets/images/secure.png"
import perfectmoney from "../assets/images/perfectmoney.jpg"
import deriv from "../assets/images/deriv.jpg"
import tether from "../assets/images/tether.jpg"
import litecoin from "../assets/images/litecoin.jpg"
import etherum from "../assets/images/etherum.jpg"
import bnb from "../assets/images/bnb.jpg"
import cardano from "../assets/images/cardano.jpg"
import solana from "../assets/images/solana.jpg"

const Home = () => {
  const navigate = useNavigate()

  return (
    <>
      <main className=" pt-14 mx-auto">
        <div className="md:px-6 container">
          <div className="md:flex justify-center flex-row-reverse gap-12 items-center">
            <div className="pt-4 bg-body md:bg-transparent md:h-80 md:w-80 lg:w-96">
              <div
                className="h-64  lg:h-80 bg-contain bg-no-repeat bg-center"
                style={{ backgroundImage: `url('/coins.png')` }}
              ></div>
            </div>

            <div className="px-6 md:px-0 ">
              <div className="max-w-4xl ">
                <h1 className="text-3xl font-bold  lg:text-4xl text-faint-black mb-2">
                  Secure And Direct E-wallet & Crypto Currency
                  <span className="block text-primary">Exchange</span>
                </h1>

                <span className="font-medium block   mb-2 text-faint-black text-lg md:text-sm  lg:text-xl leading-snug">
                  Trade your digital assets with confidence. Experience
                  unrivaled security and seamless transactions. Join us today!
                </span>
              </div>

              <Btn
                text="Trade With Us"
                style={{
                  background:
                    "linear-gradient(180deg, #000DD8 0.54%, #3263F6 99.46%",
                }}
                type="generic"
                onClick={() => {
                  window.open("https://wa.link/qv51ja", '_blank')

                  // navigate("/register")
                }}
                classNames="w-full mx-auto md:mx-0 relative hover:top-px  max-w-xs btn-primary  text-white"
              />
            </div>
          </div>

          <section className="md:flex  gap-4">
            <div className="mx-auto 2xl:ml-20 w-[384px]">
              <ExchangeCalculator />
            </div>

            <div className="px-4 mt-10 md:mt-24">
              <div className="max-w-4xl">
                <h2 className="text-2xl font-bold  lg:text-4xl text-faint-black mb-2">
                  Say farewell to lengthy waits. Convert your
                  <span className="text-primary"> PM</span> to
                  <span className="text-primary"> Naira </span>& Other E -
                  Currencies instantly
                </h2>

                <span className="font-medium block mb-2 text-faint-black  md:text-sm  lg:text-xl leading-snug">
                  Experience the end of long delays – effortlessly convert
                  Digital Currencies to Naira instantly with us.
                </span>
              </div>

              <div className="flex flex-col  md:flex-row gap-3">
                <Btn
                  text="Trade on WhatsApp"
                  type="generic"
                  onClick={() => {
                    window.open("https://wa.link/qv51ja", '_blank')

                    // navigate("/register")
                    // window.open("mailto:support@cheapnaira.com", '_blank')
                  }}
                  classNames="w-full max-w-sm grow md:w-auto  md:max-w-none font-medium mx-auto md:mx-0 relative hover:top-px   btn-primary  text-white"
                />
                <Btn
                  text="Trade on Web"
                  type="generic"
                  style={{
                    borderRadius: "0.6rem",
                    border: "1.283px solid  #3263F6",
                    boxShadow: "0px 14px 24px 0px rgba(1, 215, 207, 0.06)",
                  }}
                  onClick={() => {
                    // window.open("https://wa.link/qv51ja", '_blank')
                    navigate("/register")

                  }}
                  classNames="w-full max-w-sm grow md:w-auto md:max-w-none font-medium mx-auto md:mx-0 relative hover:top-px hover:bg-slate-100 hover:bg-slate-50   btn-secondary  text-primary"
                />
              </div>
            </div>
          </section>
        </div>

        <section className="bg-footer  mt-12 py-10 px-6 xl:px-40">
          <div className="">
            <h3 className="font-bold text-3xl mb-8 text-center">
              Why Choose Us
            </h3>

            <div className="flex flex-col md:flex-row gap-12">
              {[
                {
                  text: "All orders are processed immediately at the cheapest rates ever",
                  header: "Fast & Reliable",
                  image: fast,
                },
                {
                  text: "We offer the best exchange rates and there's no hidden fee incurred",
                  header: "Accountability",
                  image: accountability,
                },
                {
                  text: "Your transactions are super secure, giving you total peace of mind.",
                  header: "Secure Transaction",
                  image: secure,
                },
              ].map(({ text, header, image }) => (
                <div
                  key={header}
                  className="flex flex-col  gap-3 items-center justify-center  text-center"
                >
                  <img
                    alt="accountability"
                    src={image}
                    height="70"
                    width="70"
                  />
                  <span className="font-semibold text-xl">{header}</span>
                  <span className="font-normal text-sm">{text}</span>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="my-8 px-4  text-faint-black text-center">
          <div className="mb-4">
            <h3 className="font-bold text-2xl ">Currency List</h3>
            <span className="text-sm">
              Buy and sell your digital currencies at best rates
            </span>
          </div>

          <div className="flex justify-center  flex-wrap items-center">
            {[
              perfectmoney,
              deriv,
              tether,
              litecoin,
              cardano,
              etherum,
              bnb,
              solana,
            ].map((e) => (
              <div
                key={e}
                className="card card-compact mb-4 w-16 h-16 md:w-32 md:h-32 mx-4  bg-base-100 shadow-xl"
              >
                <figure>
                  <img src={e} alt=" " />
                </figure>
              </div>
            ))}
          </div>
        </section>
      </main>
    </>
  )
}
export default Home

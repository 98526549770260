import { Outlet } from "react-router-dom"
// import useAuthStatus from "../hooks/useAuthStatus"
import Spinner from "./Spinner"
import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { autoLogin, refreshToken } from "../features/auth/authSlice"

const PrivateRoute = () => {
  // const { checkingStatus } = useAuthStatus()
  const { user, isLoading } = useSelector((state) => state.auth)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const intervalIdRef = useRef(null)

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"))

    const persistedLogin = JSON.parse(localStorage.getItem("persistedLogin"))
    const staySignedIn = persistedLogin === true ? true : false

    //automatically signin if there's a token in local storage
    // if theres a token, no user , and loading is false
    if (token && !user && !isLoading) {
      dispatch(
        autoLogin({
          oldToken: token,
          staySignedIn,
        })
      )
      return
    }

    if (!isLoading && !user) {
      navigate("/login")
    }
  }, [dispatch, isLoading, navigate, user])

  useEffect(() => {
    if (user && !localStorage.getItem("persistedLogin")) {
      intervalIdRef.current = setInterval(() => {
        dispatch(refreshToken())
      }, 1680000)

      return () => clearTimeout(intervalIdRef.current)
    }
  }, [dispatch, user])

  // if (checkingStatus) return <Spinner />

  // return user ? <Outlet /> : <Navigate to="/login" />
  return user ? <Outlet /> : <Spinner />
}

export default PrivateRoute

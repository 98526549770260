import { useState } from "react"
import axios from "axios"
import { toast } from "react-toastify"

const URL = (() => {
  if (process.env.NODE_ENV === "development")
    return "http://localhost:5001/cheap-naira/us-central1/api/user/reset-password"

  return process.env.REACT_APP_API_URL + "/user/reset-password"
})()

const ForgotPassword = () => {
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    setMessage("")
    if (!email) {
      return toast.error("Please insert your email address")
    }

    setLoading(true)

    axios
      .post(URL, { email })
      .then((response) => {
        setLoading(false)
        setEmail("")
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
        setMessage(
          "If the email is registered, you will receive a password reset link."
        )
      })
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">
          Reset Password
        </h2>
        {message && <div className="mb-4 text-green-600">{message}</div>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 mb-2">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-3 border rounded-lg focus:outline-none text-gray-800 focus:ring-2 focus:ring-blue-600"
              placeholder="Enter your email"
              required
            />
          </div>
          <button
            disabled={loading ? true : false}
            type="submit"
            className="w-full bg-blue-600 text-white p-3 rounded-lg font-semibold hover:bg-blue-700 transition duration-200"
          >
            Send Reset Link
          </button>
        </form>
      </div>
    </div>
  )
}

export default ForgotPassword

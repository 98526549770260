import { useState } from "react"
import PropTypes from "prop-types"

const TetherInputCard = ({ getInputData }) => {
  const [formData, setFormData] = useState({
    address: "",
  })

  const { address } = formData

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    })

    getInputData({
      ...formData,
      [e.target.id]: e.target.value,
    })
  }

  return (
    <div>
      <div className="bg-gray-50">
        <input
          value={address}
          onChange={onChange}
          id="address"
          type="text"
          placeholder="TRC20 wallet address"
          className="input text-sm input-bordered w-full max-w-xs"
        />
      </div>
    </div>
  )
}

TetherInputCard.propTypes = {
  getInputData: PropTypes.func.isRequired,
}

export default TetherInputCard

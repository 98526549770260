import { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { register, reset } from "../features/auth/authSlice"
import { FaEye, FaEyeSlash, FaGoogle } from "react-icons/fa"
// import { ReactComponent as GoogleIcon } from "../assets/svg/google.svg"
import Btn from "../components/Button"
import Spinner from "../components/Spinner"
import ErrorInfo from "../components/ErrorInfo"

const SignUp = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    repeat_password: "",
  })
  const [showPassword, setShowPassword] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)
  const { name, email, password, repeat_password } = formData

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isSuccess, isLoading, isError, message } = useSelector(
    (state) => state.auth
  )

  useEffect(() => {
    return () => dispatch(reset())
  }, [navigate, dispatch])

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    })
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    dispatch(reset())
    dispatch(register({ name, email, password, repeat_password }))
  }

  return (
    <div className="bg-blue-dark pb-10 container mx-auto px-4">
      {isLoading && <Spinner message="Creating Profile" />}
      {isSuccess ? (
        <>
          <div className="hero min-h-screen">
            <div className="hero-content text-center bg-base-100 rounded-xl">
              <div className="max-w-md flex justify-center items-center flex-col gap-2">
                <h1 className="text-4xl font-bold text-primary">
                  Activate Your Account!
                </h1>
                <p className="py-6  text-sm text-info-content font-medium">
                  Congratulations, your account has been successfully created An
                  email has been sent to you to verify your email address. After
                  activating your account, please click on the button below to
                  login into your accout
                </p>
                {/* <Btn
                  text="Continue"
                  classNames="w-6/12"
                  onClick={() => {
                    dispatch(reset())
                    navigate("/login")
                  }}
                /> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="md:flex lg:pl-40 mx-auto justify-center items-center gap-10">
          <div className="pt-20 z-10 max-w-md mx-auto md:mx-0 text-faint-black">
            <header>
              <h1 className="text-2xl text-center font-semibold my-8">
                Create an <span className="text-primary">account</span>
              </h1>
            </header>
            <form
              className="form-control w-full md:w-80 flex  flex-col gap-3 text-sm"
              onSubmit={onSubmit}
            >
              <div className="">
                <label className="label " htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  placeholder="Enter your name"
                  className="input input-bordered border-faint-black w-full bg-transparent border"
                  value={name}
                  onChange={(e) => onChange(e)}
                />
              </div>
              <div className="">
                <label className="label " htmlFor="email">
                  Email
                </label>
                <input
                  type="text"
                  id="email"
                  placeholder="Enter your email"
                  className="input input-bordered border-faint-black w-full bg-transparent border  "
                  value={email}
                  onChange={(e) => onChange(e)}
                />
              </div>
              <div className="">
                <label className="label " htmlFor="password">
                  Password
                </label>
                <div className="relative">
                  <div className="absolute top-1/2 right-4 transform -translate-x-1/2 -translate-y-1/2  ">
                    <label className="swap">
                      <input type="checkbox" />
                      <div
                        className="swap-on"
                        onClick={() => setShowPassword(true)}
                      >
                        <FaEye />
                      </div>
                      <div
                        className="swap-off"
                        onClick={() => setShowPassword(false)}
                      >
                        <FaEyeSlash />
                      </div>
                    </label>
                  </div>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="Enter your password"
                    className="input input-bordered border-faint-black w-full bg-transparent border  "
                    value={password}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div className="">
                  <label className="label " htmlFor="password2">
                    Confirm password
                  </label>
                  <div className="relative">
                    <div className="absolute top-1/2 right-4 transform -translate-x-1/2 -translate-y-1/2  ">
                      <label className="swap">
                        <input type="checkbox" />
                        <div
                          className="swap-on"
                          onClick={() => setShowPassword2(true)}
                        >
                          <FaEye />
                        </div>
                        <div
                          className="swap-off"
                          onClick={() => setShowPassword2(false)}
                        >
                          <FaEyeSlash />
                        </div>
                      </label>
                    </div>
                    <input
                      type={showPassword2 ? "text" : "password"}
                      id="repeat_password"
                      placeholder="Confirm your password"
                      className="input input-bordered border-faint-black w-full bg-transparent border  "
                      value={repeat_password}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>

                <div className="">
                  <label className="label " htmlFor="referral-id">
                    Referral ID (Optional)
                  </label>
                  <input
                    type=""
                    id="referral-id"
                    placeholder="Referral ID"
                    className="input input-bordered border-faint-black w-full bg-transparent border  "
                    value=""
                    onChange={(e) => onChange(e)}
                  />
                </div>

                {isError && <ErrorInfo message={message} />}
              </div>

              <div className="mx-auto flex flex-col gap-3 items-center justify-center w-full">
                <Btn
                  text="Register"
                  style={{
                    background:
                      "linear-gradient(180deg, #000DD8 0.54%, #3263F6 99.46%",
                  }}
                  type="generic"
                  onClick={() => {
                    navigate("/register")
                  }}
                  classNames="w-full mx-auto md:mx-0 relative hover:top-px  max-w-xs btn-primary  text-white"
                />
                {/* <div className="text-xs text-center">or continue with</div>

                <Btn
                  text="Continue with Google"
                  type="generic"
                  onClick={() => {
                    navigate("/register")
                  }}
                  classNames="w-full font-medium gap-3 btn-primary hover:bg-transparent text-sm mx-auto md:mx-0 relative hover:top-px  bg-transparent  max-w-xs  border-faint-black text-faint-black"
                >
                  <FaGoogle />
                  Continue with Google
                </Btn> */}
              </div>
            </form>
            <div className="w-full text-sm">
              Already have an account?{" "}
              <Link to="/login" className="btn btn-link px-0 normal-case">
                Login
              </Link>
            </div>
          </div>

          <div
            className="h-64 hidden md:block  lg:h-80 max-w-md scale-150 bg-contain bg-no-repeat bg-center grow"
            style={{ backgroundImage: `url('/cryptoimage.png')` }}
          ></div>
        </div>
      )}
    </div>
  )
}

export default SignUp

const InfoText = ({ amount, fee }) => {
  return (
    <>
      <div
        className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 text-xs"
        role="alert"
      >
        <p className="font-bold">Important Information</p>
        <p>
          For orders less than $10, a small fee  will be
          charged. The estimated completion time for your order is 5 minutes.
        </p>
      </div>
    </>
  )
}

export default InfoText

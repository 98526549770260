import axios from "axios"

const API_URL = "https://us-central1-cheap-naira.cloudfunctions.net/api/user/"

// const API_URL = (() => {
//   if (process.env.NODE_ENV === "development")
//     return "http://localhost:5001/cheap-naira/us-central1/api/user/"

//   return process.env.REACT_APP_API_URL + "/user/"
// })()

const getTransactions = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.get(API_URL + "history", config)

  return response.data
}

const uploadImage = async (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  }

  const response = await axios.post(API_URL + "verify", data, config)

  return response.data
}

const getHistory = async (params, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },

    params: {
      lastDodId: params?.lastDodId,
      limit: params?.limit,
    },
  }
  const response = await axios.get(API_URL + "history", config)

  return response.data
}

const sendInVoice = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.post(
    "https://us-central1-cheap-naira.cloudfunctions.net/api/payment/pay",
    data,
    config
  )

  return response.data
}

const getPaymentConfig = async (paymentConfigID) => {
  const config = {
    params: {
      paymentConfigID,
    },
  }
  const response = await axios.get(
    "https://us-central1-cheap-naira.cloudfunctions.net/api/payment/config",
    config
  )

  return response.data
}

const appService = {
  getTransactions,
  uploadImage,
  getHistory,
  sendInVoice,
  getPaymentConfig,
}
export default appService

import useScreenSize from "../hooks/useScreenSize"

import LargeScreenNav from "./LargeScreenNav"
import MobileNav from "./MobileNav"
import TabletNav from "./TabletNav"
import { useLocation } from "react-router-dom"

const Nav = () => {
  const { pathname } = useLocation()

  const { width: screenWidth } = useScreenSize()

  return pathname.includes("/app") ? null : (
    <div className=" relative z-50">
      {screenWidth >= 1440 ? (
        <LargeScreenNav />
      ) : screenWidth >= 768 ? (
        <TabletNav />
      ) : (
        <MobileNav />
      )}
    </div>
  )
}
export default Nav

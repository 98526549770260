import PropTypes from "prop-types"

const PerfectMoney = ({ amount, paymentID, onClick }) => {
  return (
    <div>
      <form
        className="border h-40 w-80 flex flex-col gap-4"
        action="https://perfectmoney.com/api/step1.asp"
        method="POST"
        target="_blank"
      >
        <div className="flex flex-col items-center justify-center gap-4">
          <input type="hidden" name="PAYEE_ACCOUNT" value="U39334983" />
          <input type="hidden" name="PAYEE_NAME" value="Cheap Naira" />
          <input type="hidden" name="PAYMENT_ID" value={paymentID} />

          <input type="hidden" name="PAYMENT_UNITS" value="USD" />
          <input
            type="hidden"
            name="STATUS_URL"
            value="https://us-central1-cheap-naira.cloudfunctions.net/api/payment/perfect-money"
            // value="mailto:iraclemade@gmail.com"
          />
          <input
            type="hidden"
            name="PAYMENT_URL"
            value="https://cheapnaira.com"
          />
          <input type="hidden" name="PAYMENT_URL_METHOD" value="POST" />
          <input
            type="hidden"
            name="NOPAYMENT_URL"
            value="https://cheapnaira.com"
          />
          <input
            type="hidden"
            name="NOPAYMENT_URL_METHOD"
            value="https://cheapnaira.com"
          />
          <input
            type="hidden"
            name="SUGGESTED_MEMO"
            value="Cheap Naira Exchange"
          />

          <input
            type="text"
            value={amount}
            name="PAYMENT_AMOUNT"
            className="h-px invisible"
            readOnly
          />
          <input
            type="submit"
            className="btn-primary text-white rounded-md px-3  flex  border-solid  justify-center items-center  text-xs py-1 normal-case"
            name="PAYMENT_METHOD"
            value={"Continue"}
            onClick={onClick}
          />
        </div>
      </form>
    </div>
  )
}

PerfectMoney.propTypes = {
  paymentID: PropTypes.string.isRequired,
}

export default PerfectMoney

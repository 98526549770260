import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// import { toast } from "react-toastify"
import appService from "./appService"

const initialState = {
  userHistory: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
  payment: {
    pair: "",
    fromAmount: null,
    toAmount: null,
  },
  // exchangeRates: {
  //   Deriv: { "Perfect Money USD": 1005, Naira: 900 },
  //   "Perfect Money USD": { Naira: 760, USDT: 0.98 },
  //   Skrill: { Naira: 760, "Perfect Money USD": 900 },
  //   BTC: { USDT: 900, Naira: 760 },
  //   Naira: { "Perfect Money USD": 0.001282051282051282, USDT: 500 },
  //   USDT: { Naira: 1000, "Perfect Money USD": 1.04 },
  // },
  paymentStep: 1,
}

// export const getTransactions = createAsyncThunk(
//   "app/getTransactions",
//   async (_, thunkAPI) => {
//     try {
//       const token =
//         thunkAPI.getState()?.auth?.user?.token ||
//         JSON.parse(localStorage.getItem("token"))

//       if (!token) throw new Error("Please Login")

//       const transactions = await appService.getTransactions(token)

//       return transactions
//     } catch (error) {
//       const msg =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString()
//       console.error(msg)
//       return thunkAPI.rejectWithValue(msg)
//     }
//   }
// )

export const uploadImage = createAsyncThunk(
  "app/uploadImage",
  async (imageData, thunkAPI) => {
    try {
      const token =
        thunkAPI.getState()?.auth?.user?.token ||
        JSON.parse(localStorage.getItem("token"))

      if (!token) throw new Error("Please Login")

      await appService.uploadImage({ imageData, token })
    } catch (error) {
      const msg =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      console.error(msg)
      return thunkAPI.rejectWithValue(msg)
    }
  }
)

export const getHistory = createAsyncThunk(
  "app/getHistory",
  async (params, thunkAPI) => {
    try {
      const token =
        thunkAPI.getState()?.auth?.user?.token ||
        JSON.parse(localStorage.getItem("token"))

      if (!token) throw new Error("Please Login")

      return await appService.getHistory(params, token)
    } catch (error) {
      const msg =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      console.error(msg)
      return thunkAPI.rejectWithValue(msg)
    }
  }
)

export const sendInVoice = createAsyncThunk(
  "app/sendInVoice",
  async (data, thunkAPI) => {
    try {
      const token =
        thunkAPI.getState()?.auth?.user?.token ||
        JSON.parse(localStorage.getItem("token"))

      if (!token) throw new Error("Please Login")

      return await appService.sendInVoice(data, token)
    } catch (error) {
      const msg =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      console.error(msg)
      return thunkAPI.rejectWithValue(msg)
    }
  }
)

export const getPaymentConfig = createAsyncThunk(
  "app/getPaymentConfig",
  async (paymentConfigID, thunkAPI) => {
    try {
      const token =
        thunkAPI.getState()?.auth?.user?.token ||
        JSON.parse(localStorage.getItem("token"))

      if (!token) throw new Error("Please Login")
      if (!paymentConfigID) throw new Error("PaymentConfigId is missing")

      return await appService.getPaymentConfig(paymentConfigID, token)
    } catch (error) {
      const msg =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      console.error(msg)
      return thunkAPI.rejectWithValue(msg)
    }
  }
)

export const setPaymentStep = createAsyncThunk(
  "app/setPaymentStep",
  async (step, thunkAPI) => {
    if (step) {
      return thunkAPI.fulfillWithValue(step)
    } else {
      return thunkAPI.rejectWithValue("step is undefined")
    }
  }
)

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ""
    },

    configurePayment: (state, action) => {
      const payload = action.payload
      const prevState = state.payment

      state.payment = { ...prevState, ...payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadImage.pending, (state) => {
        state.isLoading = true
      })
      .addCase(uploadImage.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(uploadImage.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getHistory.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getHistory.fulfilled, (state, action) => {
        state.userHistory = [...action.payload]
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(getHistory.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(sendInVoice.pending, (state) => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(sendInVoice.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.paymentStep = 3
        state.payment = { ...state.payment, ...action.payload }
      })
      .addCase(sendInVoice.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getPaymentConfig.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getPaymentConfig.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.paymentConfig = action.payload
        state.paymentStep = 2
      })
      .addCase(getPaymentConfig.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      // .addCase(setPaymentStep.pending, (state) => {
      //   state.isLoading = true
      // })
      .addCase(setPaymentStep.fulfilled, (state, action) => {
        // state.isLoading = false
        // state.isSuccess = true
        // console.log(action.payload)
        state.paymentStep = action.payload
      })
      .addCase(setPaymentStep.rejected, (state, action) => {
        // state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  },
})

export const { reset, configurePayment } = appSlice.actions
export default appSlice.reducer

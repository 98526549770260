import {
  getPaymentConfig,
  sendInVoice,
  setPaymentStep,
} from "../features/app/appSlice"
import { useSelector, useDispatch } from "react-redux"
import { configurePayment } from "../features/app/appSlice"
import useRate from "../hooks/useRate"

import spinner from "../assets/images/spinner.gif"
import ExchangeCalculator from "../components/ExchangeCalculator"
import Button from "../components/Button"
import PerfectMoney from "../components/PerfectMoney"
import PmNgn from "../components/PmNgn"
import PmSkrill from "../components/PmSkrill"
import PmUSDT from "../components/PmUSDT"
import OrderInfo from "../components/OrderInfo"

const PaymentBox = () => {
  const { paymentStep, isLoading, isError, message, payment } = useSelector(
    (state) => state.app
  )

  const dispatch = useDispatch()

  const {
    pair,
    fromAmount,
    toAmount,
    paymentID,
    fromCurrency,
    toCurrency,
    fee,
    subtotal,
    amountToConvert,
    amountToReceive,
  } = payment
  const { exchangeRates } = useRate()

  const onSubmit = (inputData) => {
    dispatch(
      sendInVoice({
        fromAmount,
        fromCurrency,
        toCurrency,
        toAmount,
        pair,
        ...inputData,
      })
    )
  }

  const onClick = () => {
    if (!fromAmount && !toAmount) {
      return
    }

    dispatch(getPaymentConfig(100))
  }

  const handleClick = () => {
    setTimeout(() => {
      dispatch(setPaymentStep(1))
    }, 5000)
  }

  const handleFromAmountChange = (event) => {
    const fixToTwo = (amount) => {
      return Math.abs(parseFloat(amount).toFixed(2))
    }

    const validateInput = (s) => {
      const [, b] = s.toString().split(".")

      return b && b.length > 2 ? false : true
    }

    const amount = event.target.value * exchangeRates[fromCurrency][toCurrency]

    if (!validateInput(event.target.value)) return

    dispatch(
      configurePayment({
        fromAmount: fixToTwo(event.target.value),
        toAmount: fixToTwo(amount),
      })
    )
  }

  const props = {
    fromAmount,
    toAmount,
    onSubmit,
    pair,
    handleFromAmountChange,
    fromCurrency,
    toCurrency,
  }

  const stepOneProps = { onClick, isLoading }
  const stepTwoProps = { isError, message, props }
  const stepThreeProps = {
    handleClick,
    fromAmount,
    pair,
    toAmount,
    paymentID,
    fee,
    subtotal,
    amountToConvert,
    amountToReceive,
  }

  return (
    <div>
      {paymentStep > 1 && (
        <div className="mx-4 md:pl-24">
          <button
            onClick={() => {
              dispatch(setPaymentStep(paymentStep - 1))
            }}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Go Back
          </button>
        </div>
      )}
      {
        <div className="border-l-8 text-faint-black">
          {paymentStep === 1 && <StepOne {...stepOneProps} />}

          {paymentStep === 2 && <StepTwo {...stepTwoProps} />}

          {paymentStep === 3 && <StepThree {...stepThreeProps} />}
        </div>
      }
    </div>
  )
}

const m = (props, pair) => {
  switch (pair) {
    case "PM/NGN":
      return <PmNgn {...props} />
    case "PM/SKRILL":
      return <PmSkrill {...props} />
    case "PM/USDT":
      return <PmUSDT {...props} />
    default:
      return <div>Invalid input</div>
  }
}

const StepOne = ({ onClick, isLoading }) => {
  return (
    <div className="max-w-sm mx-auto flex flex-col gap-3">
      <ExchangeCalculator />

      <div className="">
        <Button
          type="primary"
          text="Continue"
          onClick={onClick}
          classNames="mx-auto block w-full"
        />
      </div>

      {isLoading ? (
        <div className="flex justify-center items-end p-6">
          <img src={spinner} alt="" height={30} width={30} />
        </div>
      ) : null}
    </div>
  )
}

const StepTwo = ({ isError, message, props }) => {
  return (
    <div>
      <div className="flex flex-col h-full gap-10 mt-10 items-center">
        <div className="text-xs text-center font-medium">
          Please make sure you have input correct information before you proceed
        </div>
        <div className="text-xs text-center italic font-medium text-red-500">
          {isError && message ? message : ""}
        </div>

        {m(props, props.pair)}
      </div>
    </div>
  )
}

const StepThree = ({
  handleClick,
  fromAmount,
  toAmount,
  pair,
  paymentID,
  props,
  fee,
  subtotal,
  amountToConvert,
  amountToReceive,
}) => {
  return (
    <div className="flex flex-col gap- justify-center items-center  pt-32 w-full">
      <p className="text-center text-xs font-medium">
        Please click the button below to continue with your payment.
        <span className="block">Your payment will open in a window</span>
      </p>
      <PerfectMoney
        amount={fromAmount}
        onClick={handleClick}
        paymentID={paymentID}
      />
      <OrderInfo
        fee={fee}
        subtotal={subtotal}
        amountToConvert={amountToConvert}
        amountToReceive={amountToReceive}
        pair={pair}
      />
    </div>
  )
}

export default PaymentBox

import { useState } from "react"
import { NavLink, Link, useNavigate } from "react-router-dom"

import Btn from "./Button"
import { ReactComponent as Logo } from "../assets/svg/logo.svg"

const navlinks = [
  // {
  //   path: "/",
  //   text: "Home",
  // },
  // {
  //   path: "/about",
  //   text: "About us",
  // },
  // {
  //   path: "/contact",
  //   text: "Contact us",
  // },
  // {
  //   path: "/how-to-exchange",
  //   text: "How to Buy/Sell",
  // },
  // {
  //   path: "/exchange-rate-calculator",
  //   text: "Exchange Rates",
  // },
]

const MobileNav = () => {
  const [isOpen, setIsOpen] = useState(false)

  const navigate = useNavigate()

  return (
    <>
      <div className="navbar px-5  fixed z-10 bg-footer">
        <div className="flex-1">
          <Link
            to="/"
            className="inline-flex pl-2  items-center justify-center mr-auto"
          >
            <Logo className="w-8 rounded-full mr-3" />
            <span className="font-bold text-lg  text-white">Cheap Naira</span>
          </Link>

          <div className="flex-none">
            <ul className="menu menu-horizontal px-1">
              <li onClick={() => setIsOpen(!isOpen)}>
                <label className="btn btn-circle swap">
                  {/* hamburger icon */}
                  <svg
                    className="swap-off fill-white"
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 512 512"
                  >
                    <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
                  </svg>
                </label>
              </li>
            </ul>
          </div>
        </div>

        {/*dropdown menu items */}
        <div
          className={`${
            !isOpen ? "hidden" : "flex"
          } navbar absolute top-16 left-0 bg-footer pb-10`}
        >
          <ul className="menu  px-5  w-full  [&_li>*]:rounded-none ">
            <li className=" w-full flex">
              {navlinks.map(({ path, text }) => (
                <NavLink
                  end
                  to={path}
                  key={path + text}
                  className={({ isActive }) =>
                    `px-2 ${isActive ? "text-primary" : "text-white"}`
                  }
                  onClick={() => setIsOpen(false)}
                >
                  {text}
                </NavLink>
              ))}
            </li>

            <Btn
              text="Register"
              type="generic"
              onClick={() => {
                navigate("/register")
                setIsOpen(false)
              }}
              classNames="w-full btn-primary text-white mb-2"
            />

            <Btn
              text="Login"
              type="generic"
              onClick={() => {
                navigate("/login")
                setIsOpen(false)
              }}
              classNames="w-full btn-outline text-primary  hover:border-primary hover:bg-base-200 "
            />
          </ul>
        </div>
      </div>
    </>
  )
}
export default MobileNav

const OrderInfo = ({
  fee,
  subtotal,
  amountToConvert,
  amountToReceive,
  pair,
}) => {
  // const formatCurrency = (amount, currency = 'NGN', locale = 'en-NG') => {
  //     return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(amount);
  //   };

  const [baseSymbol, secondarySymbol] = pair.split("/")

  return (
    <div className="w-10/12 max-w-lg  mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="bg-blue-500 text-white text-center py-4">
        <h2 className="text-lg font-bold">Order Information</h2>
      </div>
      <div className="p-4 text-s">
        <div className="flex justify-between py-2">
          <span className="font-semibold">Processing Fee:</span>
          <span>${fee}</span>
        </div>
        <div className="flex justify-between py-2">
          <span className="font-semibold">Subtotal:</span>
          <span>${subtotal}</span>
        </div>
        <div className="flex justify-between py-2">
          <span className="font-semibold">Amount to Convert:</span>
          <span>
            {baseSymbol + " "}
            {amountToConvert}
          </span>
        </div>
        <div className="flex justify-between py-2">
          <span className="font-semibold">Amount to Receive:</span>
          <span>
            {secondarySymbol + " "}
            {amountToReceive}
          </span>
        </div>
      </div>
    </div>
  )
}

export default OrderInfo

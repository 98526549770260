import { useState, useEffect } from "react"
import axios from "axios"
import { toast } from "react-toastify"

const URL = (() => {
  // if (process.env.NODE_ENV === "development")
  //   return "http://localhost:5001/cheap-naira/us-central1/api/user/verify-email"

  // return process.env.REACT_APP_API_URL + "/user/verify-email"
  return "https://us-central1-cheap-naira.cloudfunctions.net/api/user/verify-email"

})()

const RESEND_URL = (() => {
  // if (process.env.NODE_ENV === "development")
  //   return "http://localhost:5001/cheap-naira/us-central1/api/user/resend-verify-email"

  // return process.env.REACT_APP_API_URL + "/user/resend-verify-email"
  return "https://us-central1-cheap-naira.cloudfunctions.net/api/user/resend-verify-email"
})()

const EmailVerification = () => {
  const [status, setStatus] = useState("")
  const [isLoading, setIsLoading] = useState(true)

  const queryParams = new URLSearchParams(window.location.search)
  const token = queryParams.get("token")

  useEffect(() => {
    if (!token) {
      setStatus("failed")
      setIsLoading(false)
      return
    }

    try {
      axios
        .post(URL, { token })
        .then((response) => {
          setIsLoading(false)

          setStatus("success")
        })
        .catch((error) => {
          setIsLoading(false)

          setStatus("failed")
        })
    } catch (error) {
      setStatus("failed")
      setIsLoading(false)
    }
  }, [token])

  if (isLoading) return <div className="mt-36">Loading...</div>
  if (status === "pending") return null

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded shadow-lg text-center max-w-sm">
        {status === "success" ? (
          <div>
            <svg
              className="w-20 h-20 text-green-500 flex mx-auto mb-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4m0 5a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            <h2 className="text-2xl font-bold mb-4">Email Verified!</h2>
            <p className="text-gray-700 mb-4">
              Your email has been successfully verified. Thank you for
              confirming your email address.
            </p>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
              onClick={() => (window.location.href = "/login")}
            >
              Go to Login
            </button>
          </div>
        ) : (
          <>
            <svg
              className="w-16 h-16 text-red-500 mx-auto mb-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
            <h2 className="text-2xl font-bold mb-4">Verification Failed</h2>
            <p className="text-gray-700 mb-4">
              Unfortunately, your email verification link has expired or is
              invalid.
            </p>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
              onClick={() => {
                setIsLoading(true)
                axios
                  .post(RESEND_URL, { expiredToken: token })
                  .then((response) => {
                    setIsLoading(false)

                    toast.success("Email sent! Please check your inbox")

                    setStatus("pending")
                  })
                  .catch((error) => {
                    setIsLoading(false)

                    toast.error("An error occured")
                  })
              }}
            >
              Resend Verification Email
            </button>
          </>
        )}
      </div>
    </div>
  )
}

export default EmailVerification

import { Routes, Route } from "react-router-dom"
import Login from "./pages/Login"
import SignUp from "./pages/SignUp"
import Home from "./pages/Home"
import Exchange from "./pages/Exchange"
// import Profile from "./pages/Profile"
// import Contact from "./pages/Contact"
import Dashboard from "./pages/Dashboard"
import About from "./pages/About"
import ForgotPassword from "./pages/ForgotPassword"
import ResetPassword from "./pages/ResetPassword"
import Nav from "./components/Nav"
import Footer from "./components/Footer"
import PrivateRoute from "./components/PrivateRoute"
// import Verification from "./pages/Verification"
import PaymentBox from "./pages/PaymentBox"
// import Overview from "./pages/Overview"
import History from "./pages/History"
import EmailVerification from "./components/EmailVerification"
import NotFound from "./pages/NotFound"

function App() {
  return (
    <div className="overflow-hidden bg-body">
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/verify-email" element={<EmailVerification />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/register" element={<SignUp />} />
        <Route path="/how-to-exchange" element={<Exchange />} />
        <Route path="/about" element={<About />} />
        {/* <Route path="/contact" element={<Contact />} /> */}
        <Route path="/app" element={<PrivateRoute />}>
          <Route path="" element={<Dashboard />}>
            <Route path="/app/" element={<PaymentBox />} />
            {/* <Route path="/app/profile" element={<Profile />} /> */}
            {/* <Route path="/app/verification" element={<Verification />} /> */}
            <Route path="/app/history" element={<History />} />
          </Route>
        </Route>
        {/* Catch-all route for 404 Not Found */}
        <Route path="*" element={<NotFound />} />
      </Routes>

      <Footer />
    </div>
  )
}

export default App

import { Link } from "react-router-dom"

const NotFound = () => {
  return (
    <div className="h-screen flex flex-col items-center justify-center bg-gray-100 text-gray-700">
      <h1 className="text-5xl font-bold mb-4">404</h1>
      <p className="text-2xl mb-4">Oops! Page not found.</p>
      <Link to="/" className="text-blue-500 underline">
        Go back home
      </Link>
    </div>
  )
}

export default NotFound

import { useState, useEffect } from "react"
import { doc, getDoc, onSnapshot } from "firebase/firestore"
import { db } from "../firebaseConfig"

const useRate = () => {
  const [exchangeRates, setExchangeRate] = useState({})
  const [loading, setLoading] = useState(true)
  const [fromAmount, setFromAmount] = useState("")
  const [toAmount, setToAmount] = useState("")
  const [currencyPair, setCurrencyPair] = useState("")

  const [fromCurrency, setFromCurrency] = useState("")
  const [toCurrency, setToCurrency] = useState("")

  useEffect(() => {
    let unsub

    const fetchRate = async () => {
      const docRef = doc(db, "rates", "KzUXgQezzcaCDotp5AJW")
      const docSnap = await getDoc(docRef)

      const exchangeRates = docSnap.data()

      if (docSnap.exists()) {
        setExchangeRate(exchangeRates)
        setFromCurrency(Object.keys(exchangeRates)[0])

        const defaultCurency = exchangeRates[Object.keys(exchangeRates)[0]]
        setToCurrency(Object.keys(defaultCurency)[0])

        //listen realtime
        unsub = onSnapshot(doc(db, "rates", "KzUXgQezzcaCDotp5AJW"), (doc) => {
          setExchangeRate(doc.data())
        })
      } else {
        console.error("No such document! @exchangeCalculator")
      }

      setLoading(false)
    }

    fetchRate()

    return () => unsub?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    exchangeRates,
    loading,
    fromAmount,
    toAmount,
    currencyPair,
    fromCurrency,
    toCurrency,
    setExchangeRate,
    setFromAmount,
    setToAmount,
    setCurrencyPair,
    setToCurrency,
    setFromCurrency,
  }
}
export default useRate

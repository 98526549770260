import axios from "axios"

// const API_URL = (() => {
//   if (process.env.NODE_ENV === "development")
//     return "http://localhost:5001/cheap-naira/us-central1/api/user/"

//   return process.env.REACT_APP_API_URL + "/user/"
// })()

const API_URL = "https://us-central1-cheap-naira.cloudfunctions.net/api/user/"

const register = async (userData) => {
  const response = await axios.post(API_URL, userData)

  return response.data
}

const login = async (userCredentials) => {
  const response = await axios.post(API_URL + "login", userCredentials)

  const { token } = response.data

  //save token to localStorage
  if (token) {
    localStorage.setItem("token", JSON.stringify(token))
    return response.data
  }

  return null
}

const refreshToken = async (oldToken) => {
  const config = {
    headers: {
      Authorization: `Bearer ${oldToken}`,
    },
  }
  const response = await axios.post(
    API_URL + "refresh-token",
    {
      oldToken,
    },
    config
  )

  const { token: newToken } = response.data

  if (newToken) {
    //save new token to localStorage
    localStorage.setItem("token", JSON.stringify(newToken))

    return response.data
  }

  return null
}
const autoLogin = async ({ oldToken, staySignedIn }) => {
  const response = await axios.post(API_URL + "authenticate", {
    oldToken,
    staySignedIn,
  })

  const { token: newToken } = response.data

  if (newToken) {
    //save new token to localStorage
    localStorage.setItem("token", JSON.stringify(newToken))

    return response.data
  }

  return null
}

const logOut = async () => {
  localStorage.removeItem("token")
}

const getUser = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.get(API_URL, config)

  return response.data
}

const updateRecord = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.post(API_URL + "update", data, config)

  return response.data
}

const authService = {
  register,
  login,
  getUser,
  autoLogin,
  logOut,
  updateRecord,
  refreshToken,
}
export default authService

const About = () => {
  return (
    <div className="py-20">
      <div
        className="h-64 flex items-center justify-center  bg-black  bg-cover bg-no-repeat bg-center"
        style={{ backgroundImage: `url('/bgCoins.png')` }}
      >
        <h1 className="text-4xl text-white font-extrabold">About Us</h1>
      </div>

      <article className="text-faint-black container mx-auto px-6 py-6 text-lg lg:px-12  text-justify ">
        Cheap Naira is a leading e-currency service provider company and one of
        the best online crypto trading platforms in Nigeria specializing in the
        buying, selling and exchanging of e-currency in Nigeria. We grew from a
        scale business enterprise of less than 10,000 Naira daily sales volume
        to more than 1,000,000 naira. We have vast experience in cryptocurrency
        or e-currency services coupled with an experience service delivery team.
        Therefore, we are more than equal to any task. We are driven by a vision
        to provide reliable and affordable e-currency exchange services and have
        adopted several mission objectives to achieve this. Our service delivery
        is second to none, our customers can testify. We proud ourselves as one
        of the top three (3) exchangers in Nigeria and currently the first and
        only bitcoin wallet service provider in Nigeria. We are however humble
        by our loyal customers. Every one of our customer is treated uniquely
        and specially.
      </article>
    </div>
  )
}
export default About

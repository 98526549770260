import { useState, useEffect } from "react"
import { FaArrowRight } from "react-icons/fa"
import { useDispatch } from "react-redux"

import { reset } from "../features/app/appSlice"

import Button from "../components/Button"
import pmImage from "../assets/images/perfectmoney.jpg"
import tetherImage from "../assets/images/tether.jpg"
import TetherInputCard from "./TetherInputCard"
import InfoText from "./InfoText"

const PmUSDT = ({
  fromAmount,
  toAmount,
  handleFromAmountChange,
  onSubmit: s,
}) => {
  const [data, setData] = useState({})

  const dispatch = useDispatch()

  useEffect(() => {
    return () => dispatch(reset())
  }, [dispatch])

  const min = 5
  const max = 10000

  const getInputData = (inputData) => {
    setData(inputData)
  }

  const onSubmit = () => {
    s(data)
  }

  return (
    <div>
      <div className="flex flex-col  gap-10  items-center">
        <div className="flex w-full justify-evenly  items-center">
          <div className="">
            <img
              src={pmImage}
              height={35}
              width={35}
              alt=""
              className="rounded-full"
            />
          </div>
          <FaArrowRight />
          <div className="">
            <img
              src={tetherImage}
              height={35}
              width={35}
              alt=""
              className="rounded-full"
            />
          </div>
        </div>

        <div className="border h-40 w-80 flex flex-col gap-4">
          <div className="flex justify-evenly gap-4">
            <input
              type="number"
              className="input input-bordered text-center w-28 flex justify-center items-center"
              min="5"
              step="1"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
              }
              value={fromAmount}
              onChange={(e) => handleFromAmountChange(e)}
            />

            <div className="flex flex-col gap-1 text-sm">
              <span>Min ${min}</span>
              <span>Max ${max}</span>
            </div>
          </div>

          <div className="flex flex-col gap-2 pb-7 text-xs">
            <TetherInputCard getInputData={getInputData} />
            <div className="bg-gray-50">
              <Button
                onClick={onSubmit}
                type="primary"
                text="Continue"
                className="btn btn-primary mx-auto block w-full"
              />
            </div>
          </div>

          {/* {toAmount && (
            <span className="text-xs text-center">
              {`You will send ${fromAmount} perfect money USD and recieve ${new Intl.NumberFormat(
                "en-US"
              ).format(toAmount)} USDT (TRC20)`}
            </span>
          )} */}
          <InfoText amount={50} fee={1.5} />
        </div>
      </div>
    </div>
  )
}
export default PmUSDT

import { useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getHistory } from "../features/app/appSlice"
import { reset } from "../features/app/appSlice"
import { FaArrowUp } from "react-icons/fa"
import spinner from "../assets/images/spinner.gif"

const History = () => {
  const { isLoading, userHistory } = useSelector((state) => state.app)

  const dispatch = useDispatch()
  const ref = useRef()

  const limit = 8

  useEffect(() => {
    dispatch(getHistory({ limit }))

    return () => dispatch(reset())
  }, [dispatch])


  const lastDoc = userHistory[userHistory.length - 1]

  const fetchMore = () => {
    dispatch(getHistory({ limit, lastDodId: lastDoc.paymentID }))
  }

  return (
    <div ref={ref} onScroll={() => console.log(55)}>
      <h1 className="text-center text-lg font-medium" id="target_scroll">
        Transactions
      </h1>
      <div className="mx-auto  px-4 pt-8">
        <div className="flex flex-col gap-1 justify-center items-center max-w-md mx-auto relative">
          {userHistory.map((history) => {
            const {
              createTime,
              amountToReceive,
              fee,
              paymentID,
              pair,
              status,
            } = history

            return (
              <div
                key={paymentID}
                className="flex flex-col gap- p-3 text-xs md:text-sm shadow-sm w-full rounded-md bg-slate-100"
              >
                <div className="flex justify-between">
                  <span>{pair}</span>
                  <span>
                    {new Date(createTime._seconds * 1000).toLocaleString()}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Status</span>
                  <span
                    className={
                      status === "awaiting approval"
                        ? "text-warning"
                        : "text-green-500"
                    }
                  >
                    {status}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Amount</span>
                  <span>{amountToReceive}</span>
                </div>
                <div className="flex justify-between">
                  <span>fee</span>
                  <span>{fee} USD</span>
                </div>
              </div>
            )
          })}
          <div className="flex justify-center items-center p-t-3">
            {isLoading && <img src={spinner} alt="" height={18} width={18} />}
          </div>
          <div>
            {userHistory.length ? (
              <button
                type="button"
                className="p-1 shadow-sm text-xs relative active:top-1"
                onClick={fetchMore}
              >
                show more
              </button>
            ) : null}
          </div>

          <div
            className="bg-slate-100 p-4 fixed right-8 bottom-28 z-10 rounded-lg shadow-md cursor-pointer"
            onClick={() =>
              document.getElementById("target_scroll")?.scrollIntoView(false)
            }
          >
            <FaArrowUp className="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default History

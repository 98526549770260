import { useEffect, useState } from "react"
import { Link, Outlet, NavLink } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { ReactComponent as Logo } from "../assets/svg/logo.svg"

import { reset } from "../features/auth/authSlice"

import { logOut } from "../features/auth/authSlice"

import perfectmoney from "../assets/images/perfectmoney.jpg"
import { currencies } from "../data"

import profileImage from "../assets/images/profile.png"
import logo from "../assets/images/logo.jpg"

import { FaWhatsapp, FaSignOutAlt } from "react-icons/fa"

const Navbar = ({ sidebarShow, setSidebarShow }) => {
  const dispatch = useDispatch()

  const onLogOut = () => {
    dispatch(logOut())
  }

  return (
    <div className="navbar shadow-sm text-faint-black relative">
      <div className="flex-1">
        <Link to="/" className=" inline-flex pl-2 items-center justify-center">
          <Logo className=" w-8 rounded-full mr-2 fill-grey-light" />
          <span className="font-bold text-xl  text-faint-black">
            Cheap Naira
          </span>
        </Link>
      </div>
      <div className="flex-none gap-2 ml-auto">
        <div className="form-control"></div>
        <div className="dropdown dropdown-end">
          <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
            <div className="w-10 rounded-full">
              <img alt="" src={profileImage} />
            </div>
          </label>
          <ul
            tabIndex={0}
            className="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52"
          >
            {/* <li>
              <Link to="/app/profile" className="justify-between">
                Profile
              </Link>
            </li> */}
            <li>
              <Link to="/app/">Dashboard</Link>
            </li>
            <li>
              <Link to="/app/history">Transactions</Link>
            </li>
            {/* <li>
              <Link to="/app/verification">Verification</Link>
            </li> */}
            <li>
              <Link onClick={onLogOut}>Logout</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

const Dashboard = () => {
  const { isLoading } = useSelector((state) => state.auth)
  const [sidebarShow, setSidebarShow] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    // dispatch(getUser())

    return () => {
      dispatch(reset())
    }
  }, [dispatch])

  const onLogOut = () => {
    dispatch(logOut())
  }

  return isLoading ? (
    "loading"
  ) : (
    <div className="h-screen">
      <div className=" h-full relative grid-cols-none md:grid-cols-dashboard text-faint-black">
        <section className="md:border-l-8 grid grid-rows-dashboard grid-cols-1 gap-3 overflow-y-hidden">
          <div>
            <Navbar sidebarShow={sidebarShow} setSidebarShow={setSidebarShow} />
          </div>
          <div className="pb-40 h-screen overflow-y-scroll">
            <Outlet />
          </div>
        </section>
      </div>
    </div>
  )
}
export default Dashboard

import PropTypes from "prop-types"
import { Children } from "react"

const Button = ({
  text,
  type,
  onClick,
  classNames,
  children,
  ...otherProps
}) => {
  const getChildren = () => {
    if (children) {
      return Children.map(children, (child) => child)
    } else {
      return text
    }
  }

  const className =
    type === "primary"
      ? `btn-primary text-white ${classNames}`
      : type === `secondary`
      ? `border-solid px-6 bg-white hover:bg-slate-100 hover:bg-slate-50  btn-secondary hover:border-primary text-primary ${classNames}`
      : classNames

  return (
    <button
      className={`btn mx-2  flex  border-solid  justify-center items-center gap-10 text-lg normal-case ${className}`}
      onClick={onClick}
      {...otherProps}
    >
      {getChildren()}
    </button>
  )
}

Button.defaultProps = {
  type: "primary",
}

Button.propTypes = {
  // text: PropTypes.string.isRequired,
  classNames: PropTypes.string,
  type: PropTypes.oneOf(["primary", "secondary", "generic"]),
  onClick: PropTypes.func.isRequired,
}

export default Button

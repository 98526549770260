import { NavLink, Link, useNavigate } from "react-router-dom"

import Btn from "./Button"
import { ReactComponent as Logo } from "../assets/svg/logo.svg"

const navlinks = [
  // {
  //   path: "/how-to-exchange",
  //   text: "How to Buy/Sell",
  // },
  // {
  //   path: "/",
  //   text: "Home",
  // },
  // {
  //   path: "/contact",
  //   text: "Contact us",
  // },
]

const TabletNav = () => {
  const navigate = useNavigate()

  return (
    <div className="navbar bg-white px-6 fixed z-10 text-sm md:text-md lg:text-lg">
      <div className="flex-1">
        <Link to="/" className=" inline-flex pl-2 items-center justify-center">
          <Logo className=" w-8 rounded-full mr-2 fill-grey-light" />
          <span className="font-bold text-md  text-faint-black">
            Cheap Naira
          </span>
        </Link>
      </div>

      {navlinks.map(({ path, text }) => (
        <div className="flex-none" key={path + text}>
          <ul className="menu menu-horizontal px-1">
            <li>
              <NavLink
                end // to make sure / doesn't math for all paths
                to={path}
                className={({ isActive }) =>
                  `text-faint-black border-primary ${
                    isActive ? "border-b-2" : "active:bg-transparent"
                  }`
                }
              >
                {text}
              </NavLink>
            </li>
          </ul>
        </div>
      ))}
      <Btn
        text="Register"
        type="primary"
        onClick={() => navigate("/register")}
      />
      <Btn text="Login" type="secondary" onClick={() => navigate("/login")} />
    </div>
  )
}
export default TabletNav

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import authService from "./authService"

const initialState = {
  user: null,
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
  token: "",
}

export const register = createAsyncThunk(
  "auth/register",
  async (newUserData, thunkAPI) => {
    try {
      const user = await authService.register(newUserData)

      return user
    } catch (error) {
      const msg =
        error.response && error.response.data && error.response.data.message //||
      error.message || error.toString()

      console.error(msg)
      return thunkAPI.rejectWithValue(msg)
    }
  }
)

export const login = createAsyncThunk(
  "auth/login",
  async (userCredentials, thunkAPI) => {
    try {
      const user = await authService.login(userCredentials)
      return user
    } catch (error) {
      const msg =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(msg)
    }
  }
)

export const autoLogin = createAsyncThunk(
  "auth/autoLogin",
  async ({ oldToken, staySignedIn = false }, thunkAPI) => {
    try {
      const user = await authService.autoLogin({ oldToken, staySignedIn })
      return user
    } catch (error) {
      const msg =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      localStorage.removeItem("token")

      return thunkAPI.rejectWithValue(msg)
    }
  }
)

export const refreshToken = createAsyncThunk(
  "auth/refreshToken",
  async (_, thunkAPI) => {
    try {
      const newToken = await authService.refreshToken(
        thunkAPI.getState().auth.token
      )
      return newToken
    } catch (error) {
      const msg =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(msg)
    }
  }
)

export const logOut = createAsyncThunk(
  "auth/logOut",
  async () => await authService.logOut()
)

export const getUser = createAsyncThunk("auth/getUser", async (_, thunkAPI) => {
  try {
    const token =
      thunkAPI.getState()?.auth?.user?.token ||
      JSON.parse(localStorage.getItem("token"))

    if (!token) {
      thunkAPI.dispatch(logOut())
      toast.error("Please Login")
    }

    const user = await authService.getUser(token)

    return user
  } catch (error) {
    const msg =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    console.error(msg)
    return thunkAPI.rejectWithValue(msg)
  }
})

export const updateRecord = createAsyncThunk(
  "auth/updateRecord",
  async (payload, thunkAPI) => {
    if (!payload.data) {
      throw new Error("data or reset not present")
    }
    try {
      const token = thunkAPI.getState()?.auth?.user?.token
      if (!token) throw new Error("Please Login")

      const updatedRecord = await authService.updateRecord(payload.data, token)

      return updatedRecord
    } catch (error) {
      const msg =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      console.error(msg)

      // payload.reset()
      return thunkAPI.rejectWithValue(msg)
    }
  }
)

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ""
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.isLoading = true
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false
        state.user = null
        state.isError = true
        state.message = action.payload
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true

        state.user = action.payload.user
        state.token = action.payload.token
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false
        state.user = null
        state.isError = true
        state.message = action.payload
        localStorage.removeItem("token")
      })
      .addCase(getUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true

        const user = state.user

        state.user = { ...user, ...action.payload }
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isLoading = false
        state.user = null
        state.isError = true
        state.message = action.payload
      })
      .addCase(updateRecord.pending, (state) => {
        state.isLoading = true
        state.message = "updating record"
      })
      .addCase(updateRecord.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        toast.success("Success")

        const user = state.user

        state.user = { ...user, ...action.payload }
      })
      .addCase(updateRecord.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        toast.error(action.payload)
      })
      .addCase(autoLogin.pending, (state) => {
        state.isLoading = true
      })
      .addCase(autoLogin.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true

        state.user = action.payload.user
        state.token = action.payload.token
      })
      .addCase(autoLogin.rejected, (state, action) => {
        state.isLoading = false
        state.user = null
        state.isError = true
        state.message = action.payload
        localStorage.removeItem("token")
        localStorage.removeItem("persistedLogin")
      })
      .addCase(refreshToken.pending, (state) => {
        state.isLoading = true
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true

        state.token = action.payload.token
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.isLoading = false
        state.user = null
        state.isError = true
        state.token = ""
        localStorage.removeItem("token")
        localStorage.removeItem("persistedLogin")
      })
      .addCase(logOut.fulfilled, (state) => {
        state.user = null
        localStorage.removeItem("token")
        localStorage.removeItem("persistedLogin")
      })
  },
})

export const { reset } = authSlice.actions
export default authSlice.reducer

import { useState } from "react"
import axios from "axios"
import { toast } from "react-toastify"

const URL = (() => {
  if (process.env.NODE_ENV === "development")
    return "http://localhost:5001/cheap-naira/us-central1/api/user/update-password"

  return process.env.REACT_APP_API_URL + "/user/update-password"
})()

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [isPasswordChanged, setIsPasswordChanged] = useState(false)

  const queryParams = new URLSearchParams(window.location.search)
  const token = queryParams.get("token")

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setMessage("")
    if (!newPassword) {
      return
    }

    setLoading(true)

    axios
      .post(URL, { newPassword }, config)
      .then((response) => {
        setLoading(false)
        setIsPasswordChanged(true)
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error?.response?.data?.message || "Password reset failed")
      })
  }

  return isPasswordChanged ? (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg text-center">
        <div className="mb-4">
          <svg
            className="w-16 h-16 text-green-500 mx-auto"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12l2 2l4-4m1-4a9 9 0 11-18 0a9 9 0 0118 0z"
            ></path>
          </svg>
        </div>
        <h2 className="text-2xl font-bold mb-4 text-gray-800">
          Password Changed Successfully
        </h2>
        <button
          className="mt-6 bg-blue-600 text-white p-3 rounded-lg font-semibold hover:bg-blue-700 transition duration-200"
          onClick={() => (window.location.href = "/login")}
        >
          Go to Login
        </button>
      </div>
    </div>
  ) : (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">
          Reset Password
        </h2>
        {message && <div className="mb-4 text-green-600">{message}</div>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="password" className="block text-gray-700 mb-2">
              Password
            </label>
            <input
              type="newPassword"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full p-3 border rounded-lg focus:outline-none text-gray-800 focus:ring-2 focus:ring-blue-600"
              placeholder="Enter your new password"
              required
            />
          </div>
          <button
            disabled={loading ? true : false}
            type="submit"
            onClick={() => {}}
            className="w-full bg-blue-600 text-white p-3 rounded-lg font-semibold hover:bg-blue-700 transition duration-200"
          >
            Reset Password
          </button>
        </form>
      </div>
    </div>
  )
}

export default ResetPassword

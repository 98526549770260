const Exchange = () => {
  return (
    <div className="py-32 flex  flex-col gap-10 md:flex-row items-center justify-center">
      <div className="w-fit px-6">
        <h1 className="text-2xl text-center text-faint-black font-bold mb-2">
          How to <span className="text-primary">Buy</span> from us
        </h1>

        <div className="text-faint-black px-4 py-5 gap-2 bg-white  text-lg rounded-2xl">
          <ul className="flex flex-col gap-2">
            <li>⦿ Click the BUY E-currency or Quick buy button below.</li>
            <li>⦿ Click the BUY E-currency or Quick buy button below.</li>
            <li>⦿ Click the BUY E-currency or Quick buy button below.</li>
            <li>⦿ Click the BUY E-currency or Quick buy button below.</li>
            <li>⦿ Click the BUY E-currency or Quick buy button below.</li>
            <li>⦿ Click the BUY E-currency or Quick buy button below.</li>
            <li>⦿ Click the BUY E-currency or Quick buy button below.</li>
          </ul>
        </div>
      </div>

      <div className="w-fit px-6">
        <h2 className="text-2xl text-center text-faint-black font-bold mb-2">
          How To <span className="text-primary">sell</span> to us
        </h2>

        <div className="text-faint-black px-4 py-5 gap-2 bg-white text-lg  rounded-2xl">
          <ul className="flex flex-col gap-2">
            <li>⦿ Click the BUY E-currency or Quick buy button below.</li>
            <li>⦿ Click the BUY E-currency or Quick buy button below.</li>
            <li>⦿ Click the BUY E-currency or Quick buy button below.</li>
            <li>⦿ Click the BUY E-currency or Quick buy button below.</li>
            <li>⦿ Click the BUY E-currency or Quick buy button below.</li>
            <li>⦿ Click the BUY E-currency or Quick buy button below.</li>
            <li>⦿ Click the BUY E-currency or Quick buy button below.</li>
          </ul>
        </div>
      </div>
    </div>
  )
}
export default Exchange

import { useState, useEffect, useCallback } from "react"
import { doc, getDoc, onSnapshot } from "firebase/firestore"
import { configurePayment } from "../features/app/appSlice"

import PropTypes from "prop-types"
import { db } from "../firebaseConfig"

import spinner from "../assets/images/spinner.gif"
import useRate from "../hooks/useRate"
import { useDispatch } from "react-redux"

const currencyCodes = {
  "Perfect Money USD": "PM",
  "Skrill USD": "SKRILL",
  Naira: "NGN",
  "USDT (TRC20)": "USDT",
}

const fixToTwo = (amount) => {
  return Math.abs(parseFloat(amount).toFixed(2))
}

const validateInput = (s) => {
  const [, b] = s.toString().split(".")

  return b && b.length > 2 ? false : true
}

const ExchangeCalculator = ({ getExchangeData }) => {
  const dispatch = useDispatch()

  const {
    exchangeRates,
    loading,
    fromAmount,
    toAmount,
    currencyPair,
    fromCurrency,
    toCurrency,
    setFromAmount,
    setToAmount,
    setCurrencyPair,
    setToCurrency,
    setFromCurrency,
  } = useRate()

  const sendPair = useCallback(() => {
    if (getExchangeData && typeof getExchangeData === "function") {
      getExchangeData({
        pair: currencyPair,
        fromAmount,
        toAmount,
      })
    }
  }, [currencyPair, fromAmount, getExchangeData, toAmount])

  useEffect(() => {
    if (!fromCurrency || !toCurrency) return

    setCurrencyPair(
      `${currencyCodes[fromCurrency]}/${currencyCodes[toCurrency]}`
    )
    // if theres an amount to convert run
    if (fromAmount || toAmount) {
      dispatch(
        configurePayment({
          pair: currencyPair,
          fromAmount,
          toAmount,
          fromCurrency,
          toCurrency,
        })
      )
    }

    sendPair()
  }, [
    currencyPair,
    dispatch,
    fromAmount,
    fromCurrency,
    sendPair,
    setCurrencyPair,
    toAmount,
    toCurrency,
  ])

  const handleFromAmountChange = (event) => {
    if (!validateInput(event.target.value)) return

    setFromAmount(event.target.value)

    const amount = event.target.value * exchangeRates[fromCurrency][toCurrency]

    setToAmount(fixToTwo(amount))
  }

  const handleToAmountChange = (event) => {
    if (!validateInput(event.target.value)) return

    setToAmount(event.target.value)

    const amount = event.target.value / exchangeRates[fromCurrency][toCurrency]
    setFromAmount(fixToTwo(amount))
  }

  const handleFromCurrencyChange = (event) => {
    setFromCurrency(event.target.value)
    setToCurrency(Object.keys(exchangeRates[event.target.value])[0])

    // reset input fields
    setFromAmount("")
    setToAmount("")
  }

  const handleToCurrencyChange = (event) => {
    setToCurrency(event.target.value)

    // reset input fields
    setFromAmount("")
    setToAmount("")
  }

  return (
    <div className=" flex  flex-col justify-center items-center rounded-2xl max-w-sm shadow-lg h-96 w-[384px]  mt-7 bg-white px-2 py-3  text-faint-black">
      {loading ? (
        <img src={spinner} alt="" height={30} width={30} />
      ) : (
        <div className="flex  flex-col justify-center items-center  self-stretch">
          <div className="">
            <span className="font-medium">You give {fromCurrency}</span>
            <div className="px-2 py-3">
              <input
                type="number"
                value={fromAmount}
                onChange={handleFromAmountChange}
                min="0"
                step="0.01"
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                }
                className="input input-bordered  input-primary w-full max-w-xs"
              />
              <select
                className="select mt-2  select-bordered  max-w-xs"
                onChange={handleFromCurrencyChange}
              >
                {Object.keys(exchangeRates).map((currency) => (
                  <option key={currency}>{currency}</option>
                ))}
              </select>
            </div>
          </div>

          {/* <Switch className="scale-75 cursor-pointer" /> */}

          <div className="">
            <span className="font-medium">You get {toCurrency}</span>
            <div className="px-2 py-3">
              <input
                type="number"
                value={toAmount}
                onChange={handleToAmountChange}
                min="0"
                step="0.01"
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                }
                className="input input-bordered input-primary w-full max-w-xs"
              />
              <select
                className="select mt-2 select-bordered max-w-xs"
                onChange={handleToCurrencyChange}
                value={toCurrency}
              >
                {Object.keys(exchangeRates[fromCurrency]).map((currency) => (
                  <option key={currency} value={currency}>
                    {currency}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <span className="text-sm">{`1 ${fromCurrency} = ${exchangeRates[fromCurrency][toCurrency]} ${toCurrency}`}</span>
        </div>
      )}
    </div>
  )
}

ExchangeCalculator.propTypes = {
  //   text: PropTypes.string.isRequired,
  // classNames: PropTypes.string,
  //   type: PropTypes.oneOf(["primary", "secondary", "generic"]),
  getExchangeData: PropTypes.func,
}

export default ExchangeCalculator
